<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Traffic Management Application
  Author: Hossam Ali
  Author URL: https://www.linkedin.com/in/hossam-ali-7bb41810b/
========================================================================================== -->

<template>
  <div id="user-type-list">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Absence Sheet Transaction" icon-pack="feather" icon="icon-file">
            <div class="tab-text">
              <erp-reports-tab-first class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Hr Worker Planner" icon-pack="feather" icon="icon-file">
            <div class="tab-text">
              <erp-reports-tab-second class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Hr Time Attendance" icon-pack="feather" icon="icon-file">
            <div class="tab-text">
              <erp-reports-tab-third class="mt-4" />
            </div>
          </vs-tab>
        <vs-tab label="Disciplinary Action" icon-pack="feather" icon="icon-file">
            <div class="tab-text">
              <erp-reports-tab-fourth class="mt-4" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ErpReportsTabFirst from "./ErpReportsTabFirst";
import ErpReportsTabSecond from "./ErpReportsTabSecond.vue";
import ErpReportsTabThird from "./ErpReportsTabThird.vue";
import ErpReportsTabFourth from "./ErpReportsTabFourth.vue";
import ErpReportsTabFifth from "./ErpReportsTabFifth.vue";

export default {
  components: {
    ErpReportsTabFirst,
    ErpReportsTabSecond,
    ErpReportsTabThird,
    ErpReportsTabFourth,
    ErpReportsTabFifth,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>

