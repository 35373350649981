/*=========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
==========================================================================================*/

import axios from "@/axios.js";

export default {

  // fetchItems({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`${process.env.VUE_APP_BASE_URI}/users/getAllHRWorkerPlanner`)
  //       .then(response => {
  //         commit("SET_ITEMS", response.data.data);
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  fetchItemsAbsenceSheetTransaction({ commit }, params) {
   // alert(JSON.stringify(params));
   return new Promise((resolve, reject) => {

    axios
      .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/erp/absenceSheetTransaction`, {
        params: {
          from_day: params.from_day,
          to_day: params.to_day,
          company_id: params.company_id,
        }
      })
      .then(response => {
        response.data.response.data.map(item => {
          if (item.user_company) (
            item.PersonalNumber = item.user_company[0] + '-' + item.PersonalNumber
          )})
        commit("SET_ITEMSA", response.data.response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
  },
  fetchItemsHrWorkerPlanner({ commit }, params) {
    // alert(JSON.stringify(params));
    return new Promise((resolve, reject) => {
 
     axios
       .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/erp/hrWorkerPlanner`, {
         params: {
           from_day: params.from_day,
           to_day: params.to_day,
           company_id: params.company_id,
         }
       })
       .then(response => {
        response.data.response.data.map(item => {
          if (item.user_company) (
            item.RelationNumber = item.user_company[0] + '-' + item.RelationNumber
          )})
         commit("SET_ITEMSW", response.data.response.data);
         resolve(response);
       })
       .catch(error => {
         reject(error);
       });
   });
   },
   fetchItemsHrTimeAttendance({ commit }, params) {
    // alert(JSON.stringify(params));
    return new Promise((resolve, reject) => {
 
     axios
       .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/erp/hrTimeAttendance`, {
         params: {
           from_day: params.from,
           to_day: params.to,
           company_id: params.company_id,
         }
       })
       .then(response => {
        response.data.response.data.map(item => {
          if (item.user_company) (
            item.PersonalNumber = item.user_company[0] + '-' + item.PersonalNumber
          )})
         commit("SET_ITEMST", response.data.response.data);
         resolve(response);
       })
       .catch(error => {
         reject(error);
       });
   });
   },
   fetchItemsPenaltyReport({ commit }, params) {
    // alert(JSON.stringify(params));
    return new Promise((resolve, reject) => {
 
     axios
       .get(`${process.env.VUE_APP_BASE_URI}/api/admin/reports/erp/penaltyReport`, {
         params: {
           from_day: params.from,
           to_day: params.to,
           company_id: params.company_id,
         }
       })
       .then(response => {
        //  console.log(response.data.response.data)
        response.data.response.data.map(item => {
          if (item.user_company) (
            item.PersonnalNumber = item.user_company[0] + '-' + item.PersonnalNumber
          )
          if (item.Discipline === 1) {
            item.Discipline = 'FirstTime'
          } else if (item.Discipline === 2) {
            item.Discipline = 'SecondTime'
          } else if (item.Discipline === 3) {
            item.Discipline = 'ThirdTime'
          } else (
            item.Discipline = 'FourthTime'
          )
        })
         commit("SET_ITEMSP", response.data.response.data);
         resolve(response);
       })
       .catch(error => {
         reject(error);
       });
   });
   },
  fetchItemsAttendance({ commit },items) {

    items.start_date_ms = new Date(items.start_date_ms).getTime();
    items.end_date_ms = new Date(items.end_date_ms).getTime();

    // ////////console.log( items)
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BASE_URI}/users/generate_hr_time_attendance`, {
          params: {
            start_date_ms: items.start_date_ms,
            end_date_ms: items.end_date_ms
          }
        })
        .then(response => {
          commit("SET_ITEMS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchItemsTimeAttendance({ commit },items) {

    items.start_date_ms = new Date(items.start_date_ms).getTime();
    items.end_date_ms = new Date(items.end_date_ms).getTime();
    // items = JSON.stringify(items);
    // ////////console.log(items)
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BASE_URI}/users/generate_erp_hr_time_attendance`, {
          params: {
            start_date_ms: items.start_date_ms,
            end_date_ms: items.end_date_ms
          }
        })
        .then(response => {
          commit("SET_ITEMS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
