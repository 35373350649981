<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Traffic Management Application
  Author: Hossam Ali
  Author URL: https://www.linkedin.com/in/hossam-ali-7bb41810b/
========================================================================================== -->
<!-- =========================================================================================
  Author: Hossam Ali
  Author URL: https://www.linkedin.com/in/hossam-ali-7bb41810b/
========================================================================================== -->

<template>
  <div id="data-list">
    <vx-card
      ref="filterCard"
      title="Filters"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer">
        <form ref="form" slot-scope="{ validate }" autocomplete="off">
          <div class="vx-row">
            <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75 mr-3">
                <span class="text-primary">*</span>From
              </label>
              <flat-pickr
                :config="configFromdateTimePicker"
                v-model="items.from_day"
                placeholder="From Date"
                @on-change="onFromChange"
              />
            </div>

            <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75 mr-3">
                <span class="text-primary">*</span>To
              </label>
              <flat-pickr
                :config="configTodateTimePicker"
                v-model="items.to_day"
                placeholder="To Date"
                @on-change="onToChange"
              />
            </div>

            <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Company
              </label>
              <validation-provider
                name="company_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  :reduce="name => name.id"
                  :options="companies"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="items.company_id"
                  class="mb-4"
                  placeholder="Company"
                  :class="{ 'is-valid': !!errors[0] }"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div>

            <!-- <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Category
              </label>
              <validation-provider
                name="category_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  :options="categories"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="items.category_id"
                  class="mb-4 md:mb-0"
                  placeholder="Category"
                  :class="{ 'is-valid': !!errors[0] }"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div> -->

            <!-- <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Departments</label
              >

              <v-select
                :reduce="(name) => name.id"
                label="name"
                :options="departments"
                name="department_id"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.department_id"
                class="mb-4"
                placeholder="Department"
              />
            </div>

            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75"> Status </label>
              <v-select
                label="name"
                :reduce="(name) => name.key"
                :options="allStatus"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.status"
                class="mb-4"
                placeholder="Status"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">Job Title</label>
              <v-select
                :reduce="(name) => name.id"
                label="name"
                name="jobtitle_id"
                :options="jobTitles"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.jobtitle_id"
                class="mb-4"
                placeholder="Job Title"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">Employee Name</label>
              <v-select
                label="FullName"
                :reduce="(FullName) => FullName.id"
                :options="employee"
                class="w-full"
                placeholder="Emloyee Name"
                v-model="items.user_id"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">Employee Code</label>
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                v-model="items.code"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">City</label>
              <v-select
                label="name"
                :options="cities"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.city_id"
                class="mb-4"
                placeholder="City"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">Area</label>
              <v-select
                label="name"
                :reduce="(name) => name.id"
                :options="areas"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.area_id"
                class="mb-4"
                placeholder="Area"
              />
            </div>

            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">Annual Pool</label>
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                v-model="items.annual_pool"
              />
            </div> -->

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mt-6">
              <div class="vx-row">
                <div class="vx-col md:w-1/2 sm:w-1/1 w-full">
                  <vs-button
                    type="filled"
                    @click="validate().then(addNewFilter)"
                    class="w-full mb-2"
                    >Filters</vs-button
                  >
                </div>
                <!-- <div class="vx-col md:w-1/2 sm:w-1/1 w-full">
                  <vs-button
                    color="dark"
                    type="filled"
                    @click="show = !show"
                    class="w-full mb-2"
                  >
                    Advanced Filters</vs-button
                  >
                </div> -->
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
    </vx-card>
    <div class="vx-card p-6 vs-con-loading__container" id="div-with-loading">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2">
                {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  itemsData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : itemsData.length
                }}
                From {{ itemsData.length }}
              </span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(40)">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input
          class="
            sm:mr-4
            mr-0
            sm:w-auto
            w-full
            sm:order-normal
            order-3
            sm:mt-0
            mt-4
          "
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Search..."
        />
        <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
          >Export CSV</vs-button
        >
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      ></ag-grid-vue>

      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import endpoints from "../../endpoints";
import axios from "@/axios.js";
// Store Module
import moduleErpReportsManagement from "@/store/erp-reports/moduleErpReportsManagement.js";
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
import moduleDepartmentManagement from "@/store/get-all-departments/moduleDepartmentManagement.js";
import moduleEmployeeManagement from "@/store/get-all-employees/moduleEmployeeManagement.js";
import moduleCategoriesManagement from "@/store/get-all-categories/moduleCategoriesManagement.js";
import moduleJobTitleManagement from "@/store/get-all-jobs/moduleJobTitleManagement.js";
// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty"
});
extend("min", {
  ...min,
  message: "field may not be less than {length} characters"
});
export default {
  components: {
    AgGridVue,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    // Cell Renderer
    CellRendererLink,
    CellRendererStatus
  },
  data() {
    return {
      show: false,
      allStatus: [
        { key: "pending", name: "Pending" },
        { key: "accepted", name: "Accepted" },
        { key: "refused", name: "Refused" }
      ],
      cities: [],
      areas: [],
      departments: [],
      jobTitles: [],
      city: null,
      department: null,
      items: {
        from_day: null,
        to_day: null,
        company_id: null
      },
      configFromdateTimePicker: {
        allowInput: true,
        minDate: null,
        maxDate: new Date()
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date()
      },
      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        resizable: true
      },
      columnDefs: [
        {
          headerName: "Personal Number",
          field: "PersonalNumber",
          width: 300
        },
        {
          headerName: "From Date Time",
          field: "FromDateTime",
          width: 300
        },
        {
          headerName: "To Date Time",
          field: "ToDateTime",
          width: 300
        },

        {
          headerName: "Absence Code",
          field: "Absence Code",
          width: 300
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus
      }
    };
  },
  watch: {
    "items.company_id"(obj) {
      this.items.department_id = null;
      this.items.jobtitle_id = null;
      this.departments = [];
      this.jobTitles = [];
      if (obj) {
        this.$vs.loading();
        axios
          .get(`${endpoints.API_URL}company/${obj}/departments`)
          .then(response => {
            this.$vs.loading.close();
            this.departments = response.data.response.data;
          });
      }
    },
    // "items.department_id"(obj) {
    //   this.items.jobtitle_id = null;
    //   this.jobTitles = [];
    //   if (obj) {
    //     this.$vs.loading();
    //     axios
    //       .get(`${endpoints.API_URL}department/${obj}/job_titles`)
    //       .then((response) => {
    //         this.$vs.loading.close();
    //         this.jobTitles = response.data.response.data;
    //       });
    //   }
    // },
    // "items.city_id"(obj) {
    //   // console.log(obj.id);
    //   axios
    //     .get(`${process.env.VUE_APP_BASE_URI}/api/admin/city/${obj.id}/areas`)
    //     .then((response) => {
    //       this.areas = response.data.response.data;
    //     });
    // },
    to_day(obj) {
      if (obj.length === 5) {
        this.to_day = obj + ":00";
      }
    },
    from_day(obj) {
      if (obj.length === 5) {
        this.from_day = obj + ":00";
      }
    }
  },
  computed: {
    itemsData() {
      return this.$store.state.erpReportsManagement.itemsAbsence;
    },
    companies() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.company.items;
    },
    // categories() {
    //   // ////////console.log("hahahahahah : " + this.$store.state.company.items);
    //   return this.$store.state.category.items;
    // },
    // employee() {
    //   return this.$store.state.employees.items;
    // },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    addNewFilter(params) {
      if (!this.items.from_day || !this.items.to_day || !this.items.company_id) {
        this.$vs.notify({
          title: "Error",
          text: "Make sure select the main filters ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
        return;
      }
      if (params) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6
        });
        let params = {
          from_day: this.items.from_day,
          to_day: this.items.to_day,
          company_id: this.items.company_id
        };
        this.$store

          .dispatch(
            "erpReportsManagement/fetchItemsAbsenceSheetTransaction",
            params
          )
          .then(response => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            if (response.data.response.data.length === 0) {
              this.$vs.notify({
                title: "Information",
                text: "No data available",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.$vs.notify({
              title: "Error",
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          });
      } else {
        // form have errors
      }
    },
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.items.from = this.items.to = this.items.company_id = null;

      // Reset Grid Filter
      // this.gridApi.setFilterModel(null);
      // this.gridApi.onFilterChanged();
      // this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    getCompanies() {
      this.$store.dispatch("company/fetchItemsCompanies").then(response => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },
    getDepartments() {
      this.$vs.loading();
      this.$store.dispatch("department/getAlls").then(response => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },
    getCategories() {
      this.$vs.loading();
      this.$store.dispatch("category/fetchItemsCategories").then(response => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },
    getEmployee() {
      this.$vs.loading();
      this.$store.dispatch("employees/getAlls").then(response => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },
    getJobTitles() {
      this.$vs.loading();
      this.$store.dispatch("jobTitle/getAlls").then(response => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    }
  },
  mounted() {
    this.getCompanies();
    this.getDepartments();
    this.getCategories();
    this.getJobTitles();
    this.getEmployee();
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
    axios
      .get(`${process.env.VUE_APP_BASE_URI}/api/admin/cities`)
      .then(response => {
        this.cities = response.data.response.data;
      });
  },
  created() {
    if (!moduleErpReportsManagement.isRegistered) {
      this.$store.registerModule(
        "erpReportsManagement",
        moduleErpReportsManagement
      );
      moduleErpReportsManagement.isRegistered = true;
    }
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
    if (!moduleJobTitleManagement.isRegistered) {
      this.$store.registerModule("jobTitle", moduleJobTitleManagement);
      moduleJobTitleManagement.isRegistered = true;
    }
    if (!moduleDepartmentManagement.isRegistered) {
      this.$store.registerModule("department", moduleDepartmentManagement);
      moduleDepartmentManagement.isRegistered = true;
    }
    if (!moduleCategoriesManagement.isRegistered) {
      this.$store.registerModule("category", moduleCategoriesManagement);
      moduleCategoriesManagement.isRegistered = true;
    }
    if (!moduleEmployeeManagement.isRegistered) {
      this.$store.registerModule("employees", moduleEmployeeManagement);
      moduleEmployeeManagement.isRegistered = true;
    }
  }
};
</script>

<style lang="scss">
#data-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

[dir="rtl"] .ag-theme-material .ag-rtl {
  text-align: right;
}
[dir="rtl"] .ag-rtl {
  direction: rtl;
}
</style>
