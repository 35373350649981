/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Human Resources Application
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
==========================================================================================*/

export default {
  items: [],
  itemsAbsence:[],
  itemsHrWorker:[],
  itemsHrTime:[],
  itemsPenalty:[],
  total: 0,
  num_of_pages: 0
}
